/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Umair's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Umair Arshad Portfolio",
    type: "website",
    url: "http://devumairarshad.com/",
  },
};

//Home Page
const greeting = {
  title: "Umair Arshad",
  logo_name: "Umair Arshad",
  nickname: null,
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/10Z3O0pTAPbYfPrNK8v9U45BIVD6oAPy4/view?usp=sharing",
  portfolio_repository: "https://github.com/UmairArshadShk/myPortfolio",
  githubProfile: "https://github.com/UmairArshadShk",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/UmairArshadShk",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/UmairArshadShk",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/dev-umair-arshad/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:devumair.arshad@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //     {
    //       skillName: "Deeplearning",
    //       imageSrc: "deeplearning_ai_logo.png",
    //     },
    //   ],
    // },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Worked on microservice architecture",
        "⚡ Develop APIs and worked on both Backend and Front-end",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "Ruby on Rails",
          imageSrc: "rails.png",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Java Spring Boot",
          imageSrc: "java_spring_boot.png",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJs",
          imageSrc: "nextjs.png",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        }
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "Heroku",
          imageSrc: "heroku.png",
          style: {
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "My SQL",
          imageSrc: "my_sql.png",
          style: {
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Redis",
          imageSrc: "redis.png",
          style: {
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Developing and Managing Microservices",
      fileName: "DesignImg",
      skills: [
        "⚡ Building microservices using RoR, Node & Java Spring Boot",
        "⚡ Experience in building and managing microservices",
        "⚡ Creating and managing APIs",
        "⚡ Implementing Kafka and SQS for event driven architecture",
        "⚡ Monitoring and managing microservices using Datadog and Papertrail",
      ],
      softwareSkills: [
        {
          skillName: "Kafka",
          imageSrc: "kafka.png",
          style: {
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Papertrail",
          imageSrc: "papertrail.png",
          style: {
            backgroundColor: "#00b8ff"
          },
        },
        {
          skillName: "DataDog",
          imageSrc: "datadog.png",
          style: {
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  //   {
  //     siteName: "LeetCode",
  //     iconifyClassname: "simple-icons:leetcode",
  //     style: {
  //       color: "#F79F1B",
  //     },
  //     profileLink: "https://leetcode.com/layman_brother/",
  //   }
  ],
};

const degrees = {
  degrees: [
    {
      title: "Punjab University College of Information Technology",
      subtitle: "BS in Software Engineering",
      logo_path: "pucit.png",
      alt_name: "PUCIT",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, OOP etc.",
        "⚡ Apart from this, I have done courses on docker, Data Science, and Full Stack Development.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 5% of students in college. I have received award from respected director for consistently best performance in academics.",
        "⚡ I served as a Teaching Assistant in college due to my high academic performance.",
      ],
      website_link: "https://pucit.edu.pk/",
    },
    {
      title: "Superior Group of Colleges",
      subtitle: "Intermediate in Computer Science",
      logo_path: "college.png",
      alt_name: "Superior College",
      duration: "2014 - 2016",
      descriptions: [
        "⚡ I studied foundational subjects in computer science, including Programming, Database Management, and Mathematics.",
        "⚡ I participated in various technical workshops and extracurricular activities, enhancing my understanding of the field.",
        "⚡ My experience at Superior College laid a strong foundation for my future studies in software engineering."
      ],
      website_link: "https://www.superiorcolleges.edu.pk/",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked as a Backend Developer, Frontend Developer and Full Stack Developer. I have also worked with some well established companies like Revinate, one of the biggest companies in the field of reputation management for the hospitality industry.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Software Engineer",
          company: "Revinate",
          company_url: "https://www.revinate.com/",
          logo_path: "revinate.png",
          logo_style: {
            borderRadius: "15px",
            padding: "8px"
          },
          duration: "November 2022 - Present",
          location: "2345 Yale Street, First Floor, Palo Alto, CA 94306, United States",
          description:
            "Worked as a full stack developer on the company's main products like marketing, Ressales and Ivy. I have worked on the backend using Ruby on Rails, Java, nodeJS and the frontend using ReactJS and NextJS. I have also worked on the company's internal tools and services. Worked on microservice architecture and developed APIs. Added monitoring and logging to the services using Datadog and Papertrail. Worked on many internal tools and services.",
          color: "#000000",
        },
        {
          title: "Software Engineer",
          company: "GoMoment",
          company_url: "https://gomoment.com/",
          logo_path: "gomoment.png",
          logo_style: {
            borderRadius: "15px",
            padding: "15px"
          },
          duration: "June 2021 - November 2022",
          location: "2345 Yale Street, First Floor, Palo Alto, CA 94306, United States",
          description:
            "During my time at GoMoment, I worked on the company's main product Ivy. I have worked on the backend using Ruby on Rails and the frontend using ReactJS. I have worked on my PMS integrations and added new features to the product. Worked on Google dialogflow for chatbot integrations. Worked with Twilio and Bandwidth for SMS integrations.",
          color: "#0879bf",
        },
        {
          title: "Associate Software Engineer",
          company: "Coding Cops",
          company_url: "https://codingcops.com/",
          logo_path: "codingcops.png",
          logo_style: {
            borderRadius: "15px",
            padding: "12px"
          },
          duration: "July 2020 - June 2021",
          location: "Lahore, Punjab, Pakistan",
          description:
            "Worked as a full stack developer. Worked on some frontend project using ReactJS and created APIs using Ruby on Rails. Worked on Android application development.",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Side Projects",
      experiences: [
        {
          title: "Software Engineer",
          company: "Dock",
          company_url: "https://dock.bot/",
          logo_path: "dock.png",
          logo_style: {
            background: "black",
            borderRadius: "15px",
            padding: "16px"
          },
          description:
            "Explore the world of Dock. Dock is a platform that allows you to create your own chatbot. Added many new feature like Subscriptions, LPR and action logging and worked on payment gateways like Stripe and Authorize.net.",
          color: "#4285F4",
        },
        {
          title: "Freelancer",
          company: "Safety Matrix",
          company_url: "https://safetymatrix.com.pk/",
          logo_path: "safetymatrix.png",
          logo_style: {
            borderRadius: "15px",
          },
          description:
            "Develop the complete website for the company. Worked on the frontend using ReactJS and Redux. Deploy the website on Heroku and Firebase.",
          color: "#D83B01",
        },
        {
          title: "Freelancer",
          company: "GnG",
          company_url: "https://gng.com.pk/",
          logo_path: "gng.png",
          description:
            "Develop the complete website for the company. Worked on the frontend using ReactJS and Redux. Deploy the website on Heroku.",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile.jpg",
    profile_image_style: {
      borderRadius: "50%",
    },
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Saratoga Ave, San Jose, CA, USA 95129",
    locality: "San Jose",
    country: "USA",
    region: "California",
    postalCode: "95129",
    streetAddress: "Saratoga Avenue",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/NvYZqa34Wye4tpS17",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
